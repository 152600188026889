/* eslint-disable prettier/prettier */
import { ReviewDetails, ReviewProduct, ReviewSummary } from './types';
import { Foundation } from '../../.generated/Foundation.EnterpriseWeb.model';
import { fetchInternalAPIWithQueryString } from 'lib/utils/api-request-utils';

export async function getReviewsDetails(filters: Foundation.EnterpriseWeb.RenewalByAndersen.FieldSets.Reviews.ReviewFilters): Promise<ReviewDetails[]> {
    
  if(!filters.fields){
    return [];
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filters.fields),
  };

  const response = await fetchInternalAPIWithQueryString(
    `${process.env.EW_ENTERPRISE_WEB_APIv2_URL}/api/rba/reviews/get-review-details/`,
    requestOptions
  )
  const responseData= await response?.json();
  
  const result: ReviewDetails[] = responseData.map((review:ReviewDetails) => ({
    affiliateId: review?.affiliateId,
    city: review?.city,
    customerName: review?.customerName,
    id: review?.id,
    products: review?.products?.map((product: ReviewProduct) => (product)),
    review: {
      reviewId: review?.review?.reviewId,
      reviewRating: review?.review?.reviewRating,
      reviewText: review?.review?.reviewText,
      submissionDate: review?.review?.submissionDate,
      lastUpdatedDate: review?.review?.lastUpdatedDate
    },
    state: review?.state,
    yearBuilt: review?.yearBuilt
  }));

  return result;
}

export async function getReviewsSummary(filters: Foundation.EnterpriseWeb.RenewalByAndersen.FieldSets.Reviews.ReviewFilters): Promise<ReviewSummary | null>  { 
  
  if(!filters.fields){
    return null;
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filters.fields),
  };

  console.log(filters.fields)
  const response = await fetchInternalAPIWithQueryString(
   `${process.env.EW_ENTERPRISE_WEB_APIv2_URL}/api/rba/reviews/get-reviews-summary/`,
    requestOptions
  )
  const responseData= await response?.json();

  const reviewSummary: ReviewSummary = {
    id: responseData?.id,
    starRatings: responseData?.starRatings,
    totalProjects: responseData?.totalProjects,
    totalReviews: responseData?.totalReviews,
    averageRating: responseData?.averageRating
  };

  return reviewSummary;  
}
